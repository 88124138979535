import './Style.css';
import{ useState } from  'react';
import axios from 'axios';

function App() {
    const [naam, setNaam] = useState('');
    const [email, setEmail] = useState('');
    const [locatie, setLocatie] = useState('');

    const handleSubmit = () => {
        if (naam.length === 0) {
            alert("Naam mag niet leeg zijn.");
        }
        else if (locatie.length === 0) {
            alert("Gelieve een locatie te selecteren.");
        } else {
            //const url="https://webhr.com/bezoeker-registreren.php";
            const url="https://persadmin.clb-kempen.be/bezoeker-registreren.php";

            let fData = new FormData();
            fData.append('naam', naam);
            fData.append('locatie', locatie);
            fData.append('email', email);

            axios.post(url, fData)
            .then(response=> alert(response.data))
            .catch(error=> alert(error));
        }
    };


    
    return (
    <>
        <div className="container">
            
            <div className="form">
                <img className="logo" src={require('./logo.png')} alt="logo Vrij CLB Kempen" />    
                <h2>Welkom bij Vrij CLB Kempen vestiging Herentals.</h2>
                <p>Vul je gegevens in om je bezoek te registreren.</p>
                <label className="fieldlabel required"  htmlFor ="name">Naam</label>
                <input type="text" name="name" id="name" value={naam} onChange={(e) => setNaam(e.target.value)} required />
                <label className="fieldlabel"  htmlFor ="email">Email</label>
                <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                <span className="help-block">Als je je email adres invult ontvang je een mail met een link om je vertrek kan registreren.</span>
                
                <label className="fieldlabel required" htmlFor ="name">Locatie</label>
                <div>
                    <label className="radiocontainer">Medisch onderzoeksruimte
                        <input type="radio" name="locatie" value="Medisch onderzoeksruimte" onChange={(e) => setLocatie(e.target.value)}/>
                        <span className="radiobtn"></span>
                    </label>
                    <label className="radiocontainer">Gesprekslokaal
                        <input type="radio" name="locatie" value="Gesprekslokaal" onChange={(e) => setLocatie(e.target.value)}/>
                        <span className="radiobtn"></span>
                    </label>
                    <label className="radiocontainer">Vergaderzaal
                        <input type="radio" name="locatie" value="Vergaderzaal" onChange={(e) => setLocatie(e.target.value)}/>
                        <span className="radiobtn"></span>
                    </label>
                    <label className="radiocontainer">Andere
                        <input type="radio" name="locatie"value="Andere" onChange={(e) => setLocatie(e.target.value)}/>
                        <span className="radiobtn"></span>
                    </label>
                </div>
                <br/>
                <input type="button" name="send" id="send" value="Verstuur" onClick={handleSubmit} />
                <label className="requiredlegend">Verplicht veld</label>
            </div>
        </div>
    </>
  );
}

export default App;
